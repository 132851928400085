import React from "react";
import Nasir from "../img/team_pics/Nasir.png";
import Tariq from "../img/team_pics/Tariq.png";
import Luqman from "../img/team_pics/Luqman.png";
import Fawad from "../img/team_pics/Fawad.png";
import Mehwish from "../img/team_pics/Mehwish.png";
import Line from "./Line";

function MananagementMembers({getTranslatedText}) {
  const teamMembersData = [
    {
      imgSrc: Mehwish,
      title: getTranslatedText("ManagementMembers Mehvish"),
      designation: getTranslatedText("ManagementMembers MehvishDesg"),
    },
    {
      imgSrc: Nasir,
      title:getTranslatedText("ManagementMembers Nasir"),
      designation: getTranslatedText("ManagementMembers NasirDesg"),
    },
    // {
    //   imgSrc: Tariq,
    //   title: getTranslatedText("ManagementMembers Tariq"),
    //   designation: getTranslatedText("ManagementMembers TariqDesg"),
    // },
    {
      imgSrc: Luqman,
      title: getTranslatedText("ManagementMembers Ahmed"),
      designation: getTranslatedText("ManagementMembers AhmedDesg"),
    },
    // {
    //   imgSrc: Fawad,
    //   title: getTranslatedText("ManagementMembers Fawad"),
    //   designation: getTranslatedText("ManagementMembers FawadDesg"),
    // },
  ];

  return (
    <div className="container members">
      <div className="heading-wrapper">
        <Line type="large" />
        <h2 className="our-team__heading">{getTranslatedText("ManagementMembers Heading")}</h2>
      </div>

      {teamMembersData.map((data) => (
        <div class="row ">
          <div className="wrapper">
            <div className="col-lg-3">
              <div class="img">
                <img src={data.imgSrc} class="image-cover" />
              </div>
            </div>
            <div className="content col-lg-9">
              <h2>{data.title}</h2>
              <p>{data.designation}</p>
            </div>
          </div>
          <hr className="seperator"></hr>
        </div>
      ))}
    </div>
  );
}

export default MananagementMembers;
